<template>
    <div>
        <v-chart v-if="showIndex===1" :option="option1" ref="barchats1" :style="{height:(hg===1?'180px':'220px')}"></v-chart>
        <v-chart v-else :option="option2" ref="barchats2" :style="{height:(hg===1?'180px':'220px')}"></v-chart>
    </div>
</template>

<script>
import Echarts,{THEME_KEY} from 'vue-echarts'

export default{
    data:function(){
        return{
            dom1:null,
            dom2:null,
            option1:{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    data: ['0时','1时','2时','3时','4时','5时','6时','7时','8时','9时','10时','11时','12时','13时','14时','15时','16时','17时','18时','19时','20时','21时','22时','23时']
                },
                yAxis: {},
                series: [
                    {
                        name: '总计(辆)',
                        label: {
                            show: true,
                            position: 'inside'
                        },
                        type: 'bar',
                        data: []
                    }
                ]
            },
            option2:{
                title:{
                    text:this.hg===0?'':'车流量分析',
                    subtext:'',
                    left:'center'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                        data: ['0时','1时','2时','3时','4时','5时','6时','7时','8时','9时','10时','11时','12时','13时','14时','15时','16时','17时','18时','19时','20时','21时','22时','23时']
                },
                yAxis: {},
                series: [
                    {
                        name: '入场',
                        label: {
                            show: true,
                            position: 'inside'
                        },
                        type: 'bar',
                        data: []
                    },
                    {  
                        name: '出场',
                        label: {
                            show: true,
                            position: 'inside'
                        },
                        type: 'bar',
                        data:[]
                    }
                ]
            }
        }
    },
    components:{
        'v-chart':Echarts
    },
    props:{
        datalist:Array,
        showIndex:Number,
        isrefresh:Boolean,
        hg:Number
    },
    created(){
        
    },
    mounted(){
        this.dom1=this.$refs.barchats1
        this.dom2=this.$refs.barchats2
    },
    methods:{
        setConfig(){
             if(this.showIndex===1){
                this.dom1.setOption({
                    series:[{
                        data:this.datalist[0]
                    }]
                });
            }
            else{
                this.dom2.setOption({
                    series:[{
                        data:this.datalist[0]
                    },{
                        data:this.datalist[1]
                    }]
                })
            }
            
        }
        
    },
    watch:{
        isrefresh(){
            this.setConfig()
        }
    }
}
</script>

<style>
</style>