<template>
    <div>
        <dv-scroll-board :config="config"/>
    </div>
</template>

<script>
export default{
    data:function(){
        return{
            config:{
                rowNum:5,
                headerHeight:this.headerHeight,
                headerBGC:this.headerBackColor,
                header: this.headerTitle,
                data:this.dataList
            },
            refresh:this.isRefresh
        }
    },
    props:{
        headerTitle:{
            type:Array,
            default () {
                return []
            } 
        },
        dataList:{
            type:Array,
            default () {
                return []
            } 
        },
        headerBackColor:{
            type:Number,
            default(){
                return 0
            }
        },
        headerHeight:{
            type:Number,
            default(){
                return 35
            }
        },
        isRefresh:{
            type:Boolean,
            default(){
                return false
            }
        }
    },
    created(){

    },
    methods:{
        SetConifg(){
            this.config={
                rowNum:5,
                headerHeight:this.headerHeight,
                headerBGC:this.headerBackColor===0?'#00BAFF':(this.headerBackColor===1?'white':''),
                header: this.headerTitle,
                data:this.dataList
            }
        }
        
    },
    watch:{
        isRefresh(newValue,oldValue){
            this.SetConifg()
        }
    }
}
</script>

<style scoped>
</style>